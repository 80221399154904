import React, { useState, useMemo, useRef } from "react";
import "./App.css";
import YouTube, { YouTubeProps } from "react-youtube";

function App() {
  const [youtubeURL, setYoutubleURL] = useState("");
  const [playerCreated, setPlayerCrated] = useState(false);

  const iFrameRef = useRef<HTMLIFrameElement>(null);

  const youtubeVideoID = useMemo(() => {
    try {
      const searchParams = new URL(youtubeURL).searchParams;
      const qs = new URLSearchParams(searchParams);
      return qs.get("v");
    } catch {
      return "";
    }
  }, [youtubeURL]);

  const youtubeEmbedURL = useMemo(() => {
    return `https://test.ytasmr.fans/embeds/`;
  }, [youtubeVideoID]);

  const YoutubePlayer = React.forwardRef<HTMLIFrameElement>((props, ref) => {
    if (playerCreated) {
      return (
        /*
        <iframe
          ref={ref}
          width="560"
          height="315"
          src={youtubeEmbedURL}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        */
        <YouTube videoId="2g811Eo7K8U" />
      );
    } else {
      return null;
    }
  });

  const handleGo = () => {
    if (youtubeURL) {
      setPlayerCrated(true);
    }
  };

  const handleIframe = () => {
    const iFrameEl = iFrameRef.current;
    if (iFrameEl) {
      console.log(iFrameEl);
      console.log(iFrameEl.contentDocument);
    }
  };

  const handleInputChange = (ev: React.FormEvent<HTMLInputElement>) => {
    setYoutubleURL((ev.target as HTMLInputElement)?.value || "");
  };
  return (
    <div className="App">
      <label htmlFor="videoURLInput">youtube video url</label>
      <input
        onChange={handleInputChange}
        id="videooURLInput"
        className="videoURLInput"
        type="input"
      ></input>
      <button onClick={handleGo}>Go</button>
      <button onClick={handleIframe}>ifrme</button>
      <div className="player">
        {YoutubePlayer && <YoutubePlayer ref={iFrameRef} />}
      </div>
    </div>
  );
}

export default App;
